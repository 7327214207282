@import "global/var";

#loader-container {
    background: #f5f5f5;
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;

    > .spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -33px;
        margin-top: -33px;
    }
}

// loader, use with SVG markup in HTML
$offset: 187;
$duration: 1.4s;

.spinner {
    animation: rotator $duration linear infinite;
}

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

.path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite,
               colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
    0% { stroke: $brand-primary; }
    25% { stroke: $brand-danger; }
    50% { stroke: $brand-warning; }
    75% { stroke: $brand-success; }
    100% { stroke: $brand-primary; }
}

@keyframes dash {
    0% { stroke-dashoffset: $offset; }
    50% {
        stroke-dashoffset: $offset/4;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: $offset;
        transform:rotate(450deg);
    }
}